.content-root.calc-root {
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}
.content-root.calc-root .table-container .setting-section {
  align-self: flex-end;
  display: flex;
  align-items: center;
  padding: 10px 36px;
  border-radius: 8px;
}
.content-root.calc-root .table-container .setting-section label {
  font-size: 18px;
  color: #262626;
}
.content-root.calc-root .table-container .setting-section .input-container {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 16px 24px;
  height: 50px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 18px;
  background-color: #ffffff;
}
.content-root.calc-root .table-container .setting-section .input-container input {
  width: 80px;
  flex: 1;
  font-size: 18px;
  text-align: right;
}
.content-root.calc-root .table-container .setting-section > .primary-btn {
  height: 50px;
  font-size: 16px;
  padding: 16px 24px;
  width: -moz-fit-content;
  width: fit-content;
}
.content-root.calc-root .table-container .search-section .csearch-root {
  flex: 1;
}
.content-root.calc-root .table-container .search-section .csearch-root .csearch-input-container {
  width: auto;
}
.content-root.calc-root .table-container .search-section .duration-search-container {
  display: flex;
  gap: 10px;
}
.content-root.calc-root .table-container .search-section .duration-search-container .input-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
.content-root.calc-root .table-container .search-section .duration-search-container .input-wrapper .date-input {
  height: 48px;
  border-radius: 4px;
  padding: 0 10px;
}
.content-root.calc-root .table-container .search-section .duration-search-container .apply-button {
  border-radius: 4px;
  padding: 0 10px;
  background-color: #661394;
  color: #fff;
  font-size: 18px;
}
.content-root.calc-root .row .item.settleable {
  color: #661394;
  font-weight: 600;
}
.content-root.calc-root .row .item.disable {
  color: #c06312;
  font-weight: 600;
}
.content-root.calc-root .row .item.done {
  color: #000000;
}/*# sourceMappingURL=calc.styles.css.map */