* {
  margin: 0;
  padding: 0;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  -webkit-padding-before: 0;
          padding-block-start: 0;
  -webkit-padding-after: 0;
          padding-block-end: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.cselect-root {
  position: relative;
  margin: 0px 12px;
}
.cselect-root * {
  background-color: #ffffff;
}
.cselect-root .cselect-input {
  width: 260px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #e5e5ec;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.cselect-root .cselect-input.focus {
  border: 1px solid #1379d8;
}
.cselect-root .cselect-input.unfocus {
  border: 1px solid #e5e5ec;
}
.cselect-root .cselect-input .cselect-label.selected {
  color: #111111;
}
.cselect-root .cselect-input .cselect-label.unselected {
  color: #767676;
}
.cselect-root .cselect-input .cselect-under-arrow {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 16px;
}
.cselect-root .cselect-input .cselect-under-arrow img {
  width: 16px;
  height: 8px;
}
.cselect-root .cselect-option {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition-duration: 150ms;
  transition-property: max-height;
  position: absolute;
  z-index: 999;
}
.cselect-root .cselect-option.hide {
  max-height: 0px;
  overflow: hidden;
}
.cselect-root .cselect-option.show {
  max-height: 1000px;
}
.cselect-root .cselect-option .cselect-option-item {
  width: 260px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  color: #111111;
}
.cselect-root .cselect-option .cselect-option-item:not(:last-child) {
  border-bottom: 1px solid #f1f1f5;
}
.cselect-root .cselect-option .cselect-option-item:hover {
  background: rgba(19, 121, 216, 0.05);
}/*# sourceMappingURL=cselect.styles.css.map */