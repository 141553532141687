@import './cinput.styles.scss';

.cunderline-root {
  display: flex;
  flex-direction: column;
  height: 90px;

  .cunderline-label {
    font-size: 14px;
    color: $font-black;
    font-weight: 500;
    margin-bottom: 4px;

    .cunderline-input-required {
      color: $focus-color;
      vertical-align: middle;
    }
  }

  .cunderline-input {
    width: 400px;
    position: relative;

    .cunderline-disabled-input {
      width: 100%;
      height: 48px;
      font-size: 15px;
      padding-left: 12px;
      border-bottom: 1px solid $default-box-color;
      background-color: $box-background-color;
      color: $secondary-text-color;
      display: flex;
      align-items: center;
    }

    .cunderline-input-icon {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 15px;
      left: 12px;
    }

    .cunderline-input-input {
      width: 100%;
      height: 48px;
      font-size: 15px;
      padding-right: 22px;
      border-bottom: 1px solid $default-box-color;
    }

    .cunderline-delete-btn {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 15px;
      right: 15px;

      img {
        width: 100%;
      }
    }
  }

  .cunderline-error-message {
    font-size: 12px;
    color: $error-color;
    margin-top: 8px;
  }
}
