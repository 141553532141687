.add-coupon-popup-root {
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 38px 36px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.add-coupon-popup-root .limit-date-wrapper {
  display: flex;
  gap: 20px;
}
.add-coupon-popup-root .limit-date-wrapper .checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 300;
}
.add-coupon-popup-root .limit-date-wrapper .checkbox-wrapper input {
  width: 18px;
  height: 18px;
}
.add-coupon-popup-root .coupon-input-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
.add-coupon-popup-root .button-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
}
.add-coupon-popup-root .button-wrapper .button {
  flex: 1;
  padding: 15px 0;
  font-size: 18px;
}
.add-coupon-popup-root .button-wrapper .save-button {
  background-color: #661394;
  color: #fff;
}/*# sourceMappingURL=add.coupon.popup.styles.css.map */