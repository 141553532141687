.content-root.calc-detail-root {
  min-height: 100vh;
  height: fit-content;

  .body-section.basic {
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
    margin-top: 20px;
    border-radius: 4px;

    .row {
      .item {
        .label {
          display: inline-block;
          width: 100px;
          font-weight: 600;
        }
      }
    }
  }

  .table-container {
    .header-section {
      gap: 5px;

      .item {
        &.count {
          flex: 0.5;
        }
      }
    }
    .body-section {
      .store-row {
        padding-bottom: 60px;
        border-bottom: 1px solid #661394;

        &:last-child {
          border-bottom: 0;
        }

        .row {
          gap: 5px;
          cursor: default;

          &.account {
            .name {
              font-weight: 700;
            }
          }

          .item {
            flex: 1;
            text-align: center;

            &.count {
              flex: 0.5;
            }

            &.calc,
            &.cancel {
              font-weight: 600;
            }

            &.settlement {
              .input {
                border: 1px solid #e5e5ec;
                width: 100%;
                height: 40px;
                font-size: 16px;
                padding: 10px;
              }
            }

            .settlement-btn {
              background-color: #fff;
              color: #661394;
              font-weight: 600;
              font-size: 16px;
              cursor: pointer;
            }

            .cancel-btn {
              background-color: #fff;
              color: #dc0000;
              font-weight: 600;
              font-size: 16px;
              cursor: pointer;
            }

            .calc-amount {
              width: 110px;
              height: 40px;
              font-size: 16px;
              padding: 10px;
              border: 1px solid #e5e5ec;

              &:focus {
                border-color: #1379d8;
              }
            }
          }
        }
      }
    }
  }
}
