.sale-item-root.content-root {
  min-height: 100vh;
  height: fit-content;

  .csearch-root {
    flex: 1;

    .csearch-input-container {
      width: auto;
      flex: 1;
    }
  }

  .setting-section {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 10px 36px;
    border-radius: 8px;

    label {
      font-size: 18px;
      color: #262626;
    }

    .input-container {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 10px 20px;
      border: solid 1px #b1b1b1;
      border-radius: 8px;
      margin: 0 10px;
      font-size: 18px;

      input {
        width: 80px;
        flex: 1;
        font-size: 18px;
        text-align: right;
      }
    }
    .primary-btn {
      font-size: 16px;
      padding: 16px 24px;
      width: fit-content;
    }
  }

  .body-section {
    .row {
      .item {
        .store-image {
          width: 170px;
          height: 110px;
        }
      }
    }
  }
}
