.main-banner-root.content-root {
  height: fit-content;
  min-height: 100vh;

  .main-banner-image img {
    width: 200px;
    height: 150px;
    background-color: #676767;
  }

  .main-banner-priority {
    height: 48px;
    padding-left: 16px;
    border: 1px solid #e5e5ec;
    font-size: 16px;
  }
}
