@import './cinput.styles.scss';

.cselect-root {
  position: relative;
  margin: 0px 12px;

  * {
    background-color: #ffffff;
  }

  .cselect-input {
    width: 260px;
    height: 48px;
    border-radius: 4px;
    border: 1px solid $default-box-color;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 16px;

    &.focus {
      border: 1px solid $focus-color;
    }

    &.unfocus {
      border: 1px solid $default-box-color;
    }

    .cselect-label {
      &.selected {
        color: #111111;
      }

      &.unselected {
        color: #767676;
      }
    }

    .cselect-under-arrow {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 12px;
      right: 16px;

      img {
        width: 16px;
        height: 8px;
      }
    }
  }

  .cselect-option {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    transition-duration: 150ms;
    transition-property: max-height;
    position: absolute;
    z-index: 999;

    &.hide {
      max-height: 0px;
      overflow: hidden;
    }

    &.show {
      max-height: 1000px;
    }

    .cselect-option-item {
      width: 260px;
      height: 48px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      color: #111111;

      &:not(:last-child) {
        border-bottom: 1px solid #f1f1f5;
      }

      &:hover {
        background: rgba(19, 121, 216, 0.05);
      }
    }
  }
}
