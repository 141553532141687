.notice-root.content-root {
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
}
.notice-root.content-root .body-section {
  display: flex;
  flex-direction: column;
}
.notice-root.content-root .body-section .row.content {
  flex: 1;
  min-height: auto;
  align-items: unset;
  display: flex;
}
.notice-root.content-root .body-section .row.content #notice-detail-content {
  flex: 1;
  resize: none;
  border: 1px solid #e5e5ec;
  padding: 15px;
  font-size: 15px;
  border-radius: 4px;
  overflow-y: auto;
}

.notice-event-root.content-root {
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
}/*# sourceMappingURL=notice.styles.css.map */