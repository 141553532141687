.content-root.signup-request-detail-root {
  min-height: 100vh;
  height: fit-content;

  .table-title {
    font-size: 20px;
    font-weight: 600;
    margin: 12px 0;
  }

  .body-section {
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px 30px;
    margin-top: 20px;
    border-radius: 4px;

    .row {
      cursor: default !important;
      &.image {
        grid-column-start: 1;
        grid-column-end: 4;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        img {
          width: 240px;
          height: 180px;
        }
      }

      .item.only-view {
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        .label {
          width: 100px;
          font-weight: 600;
          text-align: left;
          word-break: keep-all;
        }
      }
    }
  }

  .store-file-section {
    display: flex;
    padding: 20px;
    gap: 50px;

    .business-file-section {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 40px;
    }

    .rest-day-section {
      min-width: fit-content;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .week-container {
        display: flex;
        gap: 10px;
      }

      .item {
        cursor: default;
        padding: 5px 10px;
        background-color: #ffffff;
        border: 1px solid #b1b1b1;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.selected {
          background-color: #707070;
        }
      }

      .item.week,
      .item.holidays {
        width: 80px;
      }
    }
  }
}
