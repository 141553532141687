$black: #464255;
$main: #661394;

.content {
  .qr-point-root {
    padding: 26px;
    min-height: 100vh;
    height: fit-content;

    .search-container {
      display: flex;
      align-items: center;
      margin-top: 24px;
      gap: 20px;

      .input-container {
        display: flex;
        background-color: #ffffff;
        border-radius: 4px;
        overflow: hidden;

        .input-box {
          display: flex;
          align-items: center;
          height: 48px;
          font-size: 16px;
          padding: 0 16px;

          &.search {
            width: 360px;
          }
        }

        .input-button {
          width: 48px;
          cursor: pointer;
          img {
            width: 16px;
          }
        }
      }
      .date-box {
        display: flex;
        align-items: center;
        gap: 10px;

        .date-title {
          font-size: 16px;
          font-weight: 500;
          color: $black;
        }
        .date-input {
          height: 48px;
          padding: 12px 16px;
          border-radius: 4px;
        }
      }
    }

    .body-section {
      .row {
        cursor: default;

        .item {
          .qr-point-btn {
            background-color: #fff;
            border: 1px solid $main;
            padding: 10px 16px;
            border-radius: 4px;
            font-size: 16px;
            color: $main;
          }
        }
      }
    }
  }
}
