.content-root.coupon-add {
  gap: 40px;
}
.content-root.coupon-add .title-container {
  display: flex;
  justify-content: space-between;
}
.content-root.coupon-add .title-container .add-coupon-button {
  background-color: #661394;
  width: 200px;
  border-radius: 12px;
  color: #ffffff;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}
.content-root.coupon-add .body-section {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 38px 36px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.content-root.coupon-add .body-section .limit-date-wrapper {
  display: flex;
  gap: 20px;
}
.content-root.coupon-add .body-section .limit-date-wrapper .checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 300;
}
.content-root.coupon-add .body-section .limit-date-wrapper .checkbox-wrapper input {
  width: 18px;
  height: 18px;
}
.content-root.coupon-add .body-section .coupon-input-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}/*# sourceMappingURL=coupon.add.styles.css.map */