.sale-item-detail-root.content-root {
  min-height: 100vh;
  height: fit-content;

  .body-section {
    .row.store-info {
      background-color: #fff;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      padding: 20px;
      border-radius: 4px;
      margin: 50px 0;

      .item {
        display: flex;
        align-items: center;

        &.image {
          grid-row-start: 1;
          grid-row-end: 3;
          .store-image {
            width: 170px;
            height: 110px;
            background-color: #828282;
          }
        }

        .item-title {
          width: 130px;
          font-weight: 600;
        }
      }
    }

    .row.discount {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      border-radius: 4px;

      .item {
        display: flex;
        align-items: center;
        gap: 20px;
        .item-title {
          font-weight: 600;
        }

        .discount-input {
          border: 1px solid #e5e5ec;
          padding: 10px 20px;
          border-radius: 4px;

          &:focus {
            border-color: #1379d8;
          }
        }
      }

      .save-btn {
        width: 150px;
      }
    }
  }

  .back-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 150px;
  }
}
