.event-root.content-root {
  height: fit-content;
  min-height: 100vh;
  .event-input-container {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 4px;
    margin: 24px 0px;
    display: flex;
    flex-direction: row;
    gap: 100px;

    .image-preview-section {
      .image-placeholder {
        background-color: #676767;
        width: 400px;
        height: 400px;
      }
    }

    .input-section {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 30px;

      .input-item {
        display: flex;
        flex-direction: column;
        gap: 10px;

        input,
        textarea {
          font-size: 15px;
          border: 1px solid #e5e5ec;
          padding: 10px;
          max-width: 300px;
        }

        textarea {
          resize: none;
          height: 100px;
        }

        .date-container {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      .file-input-item {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .file-input {
          display: flex;

          .file-input-label {
            display: flex;

            .file-text {
              width: 300px;
              border: 1px solid #e5e5ec;
              padding-left: 16px;
              display: flex;
              align-items: center;
              font-size: 15px;
              color: #191919;
            }

            .select-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100px;
            }
          }

          .preview-btn {
            width: 120px;
          }
        }
      }
    }
  }
}
