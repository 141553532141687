#root .item-detail .body-section.description {
  min-height: -moz-fit-content;
  min-height: fit-content;
}
#root .item-detail .body-section.description .description {
  display: flex;
  flex-direction: column;
}
#root .item-detail .body-section.description .description .add-btn {
  width: 327px;
  padding: 10px;
  border: 1px solid #828282;
  color: #ffffff;
  background-color: #661394;
  border-radius: 4px;
  align-self: center;
}
#root .item-detail .body-section.description .description .description-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
  padding: 0;
  width: 100%;
}
#root .item-detail .body-section.description .description .description-item-wrapper .file-wrapper {
  display: flex;
  padding: 0 3px 3px;
  gap: 20px;
}
#root .item-detail .body-section.description .description .description-item-wrapper .file-wrapper .label {
  cursor: pointer;
}
#root .item-detail .body-section.description .description .description-item-wrapper .file-wrapper .label .add-file-input {
  width: 125px;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  background-color: #d9d9d9;
  color: #ffffff;
  font-weight: 200;
  border-radius: 4px;
}
#root .item-detail .body-section.description .description .description-item-wrapper .file-wrapper .description-file-image {
  width: 125px;
  height: 125px;
  background-color: #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#root .item-detail .body-section.description .description .description-item-wrapper .file-wrapper .description-file-image:hover {
  opacity: 0.6;
  cursor: pointer;
}
#root .item-detail .body-section.description .description .description-item-wrapper .file-wrapper .preview-btn {
  flex: 1;
  background-color: transparent;
  color: #661394;
  text-align: right;
}
#root .item-detail .body-section.description .description .description-item-wrapper .input-wrapper {
  display: flex;
  gap: 5px;
}
#root .item-detail .body-section.description .description .description-item-wrapper .input-wrapper .description-input {
  flex: 1;
  width: 100%;
  padding: 5px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  height: 196px;
  resize: none;
  padding: 17px 20px;
}
#root .item-detail .body-section.description .description .description-item-wrapper .delete-description-btn {
  align-self: flex-end;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbfbfb;
  padding: 10px;
  border-radius: 4px;
  color: #828282;
  border: 1px solid #bdbdbd;
}/*# sourceMappingURL=item.description.styles.css.map */