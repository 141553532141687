.content-root.user-payment .table-container .body-section .payment-item:not(:last-child) {
  border-bottom: 2px solid #c8c6c9;
}
.content-root.user-payment .table-container .body-section .row {
  cursor: default;
  display: flex;
  width: 100%;
  gap: 10px;
}
.content-root.user-payment .table-container .body-section .row.account {
  cursor: default;
  flex-direction: column;
  padding: 30px 0;
  align-items: flex-end;
  font-size: 18px;
  gap: 20px;
}
.content-root.user-payment .table-container .body-section .row.account > .item {
  flex: none;
  padding: 0 20px;
}
.content-root.user-payment .table-container .body-section .row .item {
  flex: 1;
  text-align: center;
}/*# sourceMappingURL=user.payment.list.styles.css.map */