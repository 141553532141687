$main: #4a0b6d;
$main200: #651d8d;
$white: #f2f3f5;

.gnb-root {
  width: 260px;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $main200;
  overflow-y: scroll;

  .logo-container {
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 24px;

    .logo-desc {
      align-self: flex-start;
      color: $white;
      margin-left: 5px;
      font-weight: 500;
    }
  }

  .gnb-item-container {
    flex: 1;
    cursor: pointer;
    width: 100%;
    min-height: 80px;
    color: $white;
    font-size: 22px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    border-left: 8px solid $main200;

    &:hover {
      background-color: $main;
      border-left-color: $main;
      color: $white;
    }

    &.selected {
      background-color: $main;
      border-left-color: $white;
      color: $white;
    }

    .gnb-item-label {
      background-color: transparent;
    }
  }

  .logout-btn {
    cursor: pointer;
    width: 200px;
    min-height: 60px;
    font-size: 20px;
    margin: 32px 0;
    background-color: $main;
    color: #ffffff;
    padding: 10px;
    border-radius: 4px;
  }
}
