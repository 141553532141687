$main: #4a0b6d;

.content-root.user-detail {
  height: fit-content;
  .table-container.basic-info {
    flex-direction: column;
    .body-section {
      min-height: fit-content;
      margin: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      padding: 25px 30px;

      .col {
        display: flex;
        flex-direction: column;
        gap: 22px;

        .input-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;

          .input-label {
            font-size: 15px;
            color: #262626;
            width: 80px;
            align-self: flex-start;
          }

          select::-ms-expand {
            display: none;
          }

          select {
            -o-appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }

          .input-box {
            width: 300px;
            padding: 16px;
            border: 1px solid #e5e5ec;
            font-size: 16px;

            &.point {
              width: 160px;
            }

            &.disabled {
              border-color: #f2f3f5;
              background-color: #f2f3f5;
            }
          }

          .input-button {
            width: 120px;
            height: 100%;
            margin-left: 20px;
            border-radius: 4px;
            background-color: $main;
            color: #ffffff;
            font-size: 20px;
          }
        }
      }

      .row {
        .item {
          .cdate-input-root {
            width: 300px;
            input {
              width: 100%;
            }
          }

          .cselect-root {
            .cselect-input {
              width: 300px;
            }
          }
        }

        .item.point {
          display: flex;
          gap: 15px;

          .primary-btn {
            width: 90px;
            font-size: 14px;
          }
        }
      }
    }
  }

  .content-root.payment-info {
    padding: 0;
    margin-top: 40px;

    .content-root {
      padding: 0;
    }

    .content-root.user-coupon-list {
      .delete-btn {
        width: fit-content;
        padding: 0 20px;
        font-size: 14px;
      }
    }
  }
}
