.cpaging-root {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  .move-btn {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e5e7e8;
    color: #4a0b6d;
    cursor: pointer;
    margin: 0px 3px;
  }

  .number-btn {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #d8d8d8;
    background: #ffffff;
    cursor: pointer;
    margin: 0px 5px;

    &.current {
      background: #4a0b6d;
      color: #ffffff;
    }
  }
}
