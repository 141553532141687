#root {
  .item-detail {
    .body-section.description {
      min-height: fit-content;

      .description {
        display: flex;
        flex-direction: column;

        .add-btn {
          width: 327px;
          padding: 10px;
          border: 1px solid #828282;
          color: #ffffff;
          background-color: #661394;
          border-radius: 4px;
          align-self: center;
        }

        .description-item-wrapper {
          display: flex;
          flex-direction: column;
          gap: 15px;
          margin-bottom: 20px;
          padding: 0;
          width: 100%;

          .file-wrapper {
            display: flex;
            padding: 0 3px 3px;
            gap: 20px;

            .label {
              .add-file-input {
                width: 125px;
                height: 125px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 60px;
                background-color: #d9d9d9;
                color: #ffffff;
                font-weight: 200;
                border-radius: 4px;
              }
              cursor: pointer;
            }

            .description-file-image {
              width: 125px;
              height: 125px;
              background-color: #d9d9d9;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;

              &:hover {
                opacity: 0.6;
                cursor: pointer;
              }
            }

            .preview-btn {
              flex: 1;
              background-color: transparent;
              color: #661394;
              text-align: right;
            }
          }

          .input-wrapper {
            display: flex;
            gap: 5px;
            .description-input {
              flex: 1;
              width: 100%;
              padding: 5px;
              border: 1px solid #bdbdbd;
              border-radius: 4px;
              height: 196px;
              resize: none;
              padding: 17px 20px;
            }
          }

          .delete-description-btn {
            align-self: flex-end;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fbfbfb;
            padding: 10px;
            border-radius: 4px;
            color: #828282;
            border: 1px solid #bdbdbd;
          }
        }
      }
    }
  }
}
