.sale-item-detail-root.content-root {
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}
.sale-item-detail-root.content-root .body-section .row.store-info {
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 20px;
  border-radius: 4px;
  margin: 50px 0;
}
.sale-item-detail-root.content-root .body-section .row.store-info .item {
  display: flex;
  align-items: center;
}
.sale-item-detail-root.content-root .body-section .row.store-info .item.image {
  grid-row-start: 1;
  grid-row-end: 3;
}
.sale-item-detail-root.content-root .body-section .row.store-info .item.image .store-image {
  width: 170px;
  height: 110px;
  background-color: #828282;
}
.sale-item-detail-root.content-root .body-section .row.store-info .item .item-title {
  width: 130px;
  font-weight: 600;
}
.sale-item-detail-root.content-root .body-section .row.discount {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 4px;
}
.sale-item-detail-root.content-root .body-section .row.discount .item {
  display: flex;
  align-items: center;
  gap: 20px;
}
.sale-item-detail-root.content-root .body-section .row.discount .item .item-title {
  font-weight: 600;
}
.sale-item-detail-root.content-root .body-section .row.discount .item .discount-input {
  border: 1px solid #e5e5ec;
  padding: 10px 20px;
  border-radius: 4px;
}
.sale-item-detail-root.content-root .body-section .row.discount .item .discount-input:focus {
  border-color: #1379d8;
}
.sale-item-detail-root.content-root .body-section .row.discount .save-btn {
  width: 150px;
}
.sale-item-detail-root.content-root .back-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 150px;
}/*# sourceMappingURL=sale.item.detail.styles.css.map */