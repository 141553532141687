.event-root.content-root {
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
}
.event-root.content-root .event-input-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  margin: 24px 0px;
  display: flex;
  flex-direction: row;
  gap: 100px;
}
.event-root.content-root .event-input-container .image-preview-section .image-placeholder {
  background-color: #676767;
  width: 400px;
  height: 400px;
}
.event-root.content-root .event-input-container .input-section {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
}
.event-root.content-root .event-input-container .input-section .input-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.event-root.content-root .event-input-container .input-section .input-item input,
.event-root.content-root .event-input-container .input-section .input-item textarea {
  font-size: 15px;
  border: 1px solid #e5e5ec;
  padding: 10px;
  max-width: 300px;
}
.event-root.content-root .event-input-container .input-section .input-item textarea {
  resize: none;
  height: 100px;
}
.event-root.content-root .event-input-container .input-section .input-item .date-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.event-root.content-root .event-input-container .input-section .file-input-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.event-root.content-root .event-input-container .input-section .file-input-item .file-input {
  display: flex;
}
.event-root.content-root .event-input-container .input-section .file-input-item .file-input .file-input-label {
  display: flex;
}
.event-root.content-root .event-input-container .input-section .file-input-item .file-input .file-input-label .file-text {
  width: 300px;
  border: 1px solid #e5e5ec;
  padding-left: 16px;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #191919;
}
.event-root.content-root .event-input-container .input-section .file-input-item .file-input .file-input-label .select-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
}
.event-root.content-root .event-input-container .input-section .file-input-item .file-input .preview-btn {
  width: 120px;
}/*# sourceMappingURL=event.detail.styles.css.map */