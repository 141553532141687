.cody-popup-root {
  height: 80vh;
  max-width: 1000px;
  background-color: #fff;
  overflow-y: auto;

  .table-container.cody {
    .product-image {
      width: 170px;
      height: 110px;
      background-color: #828282;
    }

    .select-cody {
      width: 100px;
      font-size: 16px;
    }
  }
}
