.content-root.user-detail {
  height: -moz-fit-content;
  height: fit-content;
}
.content-root.user-detail .table-container.basic-info {
  flex-direction: column;
}
.content-root.user-detail .table-container.basic-info .body-section {
  min-height: -moz-fit-content;
  min-height: fit-content;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 25px 30px;
}
.content-root.user-detail .table-container.basic-info .body-section .col {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.content-root.user-detail .table-container.basic-info .body-section .col .input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}
.content-root.user-detail .table-container.basic-info .body-section .col .input-container .input-label {
  font-size: 15px;
  color: #262626;
  width: 80px;
  align-self: flex-start;
}
.content-root.user-detail .table-container.basic-info .body-section .col .input-container select::-ms-expand {
  display: none;
}
.content-root.user-detail .table-container.basic-info .body-section .col .input-container select {
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.content-root.user-detail .table-container.basic-info .body-section .col .input-container .input-box {
  width: 300px;
  padding: 16px;
  border: 1px solid #e5e5ec;
  font-size: 16px;
}
.content-root.user-detail .table-container.basic-info .body-section .col .input-container .input-box.point {
  width: 160px;
}
.content-root.user-detail .table-container.basic-info .body-section .col .input-container .input-box.disabled {
  border-color: #f2f3f5;
  background-color: #f2f3f5;
}
.content-root.user-detail .table-container.basic-info .body-section .col .input-container .input-button {
  width: 120px;
  height: 100%;
  margin-left: 20px;
  border-radius: 4px;
  background-color: #4a0b6d;
  color: #ffffff;
  font-size: 20px;
}
.content-root.user-detail .table-container.basic-info .body-section .row .item .cdate-input-root {
  width: 300px;
}
.content-root.user-detail .table-container.basic-info .body-section .row .item .cdate-input-root input {
  width: 100%;
}
.content-root.user-detail .table-container.basic-info .body-section .row .item .cselect-root .cselect-input {
  width: 300px;
}
.content-root.user-detail .table-container.basic-info .body-section .row .item.point {
  display: flex;
  gap: 15px;
}
.content-root.user-detail .table-container.basic-info .body-section .row .item.point .primary-btn {
  width: 90px;
  font-size: 14px;
}
.content-root.user-detail .content-root.payment-info {
  padding: 0;
  margin-top: 40px;
}
.content-root.user-detail .content-root.payment-info .content-root {
  padding: 0;
}
.content-root.user-detail .content-root.payment-info .content-root.user-coupon-list .delete-btn {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 20px;
  font-size: 14px;
}/*# sourceMappingURL=user.detail.styles.css.map */