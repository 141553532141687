.content-root.partners-coupon .table-container .title-section {
  display: flex;
  justify-content: space-between;
}
.content-root.partners-coupon .table-container .title-section .add-coupon-button {
  background-color: #661394;
  padding: 5px 16px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}
.content-root.partners-coupon .table-container .header-section {
  width: 100%;
}
.content-root.partners-coupon .table-container .body-section {
  flex-direction: column;
}
.content-root.partners-coupon .table-container .body-section .row {
  display: flex;
  gap: 10px;
}
.content-root.partners-coupon .table-container .body-section .row .item {
  flex: 1;
}
.content-root.partners-coupon .table-container .body-section .row .item input {
  text-align: center;
  font-size: 14px;
  border: 1px solid #e5e5ec;
  background-color: white;
  padding: 10px 0;
}
.content-root.partners-coupon .table-container .body-section .row .item input:focus {
  border: 1px solid rgb(19, 121, 216);
}
.content-root.partners-coupon .table-container .body-section .row .item.button-wrapper {
  border: 0;
}
.content-root.partners-coupon .table-container .body-section .row .item.button-wrapper .button {
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.content-root.partners-coupon .table-container .body-section .row .item.button-wrapper .button.delete {
  border: 1px solid #ff0000;
  color: #ff0000;
  background-color: white;
}
.content-root.partners-coupon .table-container .body-section .row .item.button-wrapper .button.save {
  background-color: #661394;
  color: white;
}/*# sourceMappingURL=partners.detail.coupon.styles.css.map */