.content-root.user-payment {
  .table-container {
    .body-section {
      .payment-item:not(:last-child) {
        border-bottom: 2px solid #c8c6c9;
      }

      .row {
        cursor: default;
        display: flex;
        width: 100%;
        gap: 10px;

        &.account {
          cursor: default;
          flex-direction: column;
          padding: 30px 0;

          align-items: flex-end;
          font-size: 18px;
          gap: 20px;

          & > .item {
            flex: none;
            padding: 0 20px;
          }
        }

        .item {
          flex: 1;
          text-align: center;
        }
      }
    }
  }
}
