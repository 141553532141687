.main-banner-detail-root .main-banner-detail-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 12px 0px;
}
.main-banner-detail-root .main-banner-detail-input-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  margin: 24px 0px;
  display: flex;
  flex-direction: row;
  gap: 100px;
  align-items: center;
}
.main-banner-detail-root .main-banner-detail-input-container .image-preview-section .image-placeholder {
  background-color: #676767;
  width: 400px;
  height: 400px;
}
.main-banner-detail-root .main-banner-detail-input-container .input-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.main-banner-detail-root .main-banner-detail-input-container .input-section .input-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.main-banner-detail-root .main-banner-detail-input-container .input-section .input-item input,
.main-banner-detail-root .main-banner-detail-input-container .input-section .input-item textarea {
  font-size: 15px;
  border: 1px solid #e5e5ec;
  padding: 10px;
  max-width: 300px;
}
.main-banner-detail-root .main-banner-detail-input-container .input-section .input-item textarea {
  resize: none;
  height: 100px;
}
.main-banner-detail-root .main-banner-detail-input-container .input-section .input-item .preview-btn {
  width: 100px;
  font-size: 18px;
}
.main-banner-detail-root .main-banner-detail-input-container .input-section .file-input-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.main-banner-detail-root .main-banner-detail-input-container .input-section .file-input-item .file-input {
  display: flex;
}
.main-banner-detail-root .main-banner-detail-input-container .input-section .file-input-item .file-input .file-input-label {
  display: flex;
}
.main-banner-detail-root .main-banner-detail-input-container .input-section .file-input-item .file-input .file-input-label .file-text {
  width: 300px;
  border: 1px solid #e5e5ec;
  padding-left: 16px;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #191919;
}
.main-banner-detail-root .main-banner-detail-input-container .input-section .file-input-item .file-input .file-input-label .select-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
}
.main-banner-detail-root .main-banner-detail-input-container .input-section .file-input-item .file-input .preview-btn {
  width: 120px;
}/*# sourceMappingURL=main.banner.detail.styles.css.map */