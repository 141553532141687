.content-root.detail-payment {
  .table-container {
    .header-section {
      .item {
        &.payment-uid {
          width: 80px;
        }
      }
    }

    .body-section {
      .row {
        width: 100%;

        .item {
          &.payment-uid {
            width: 80px;
          }
        }
      }
    }
  }
}
