.content-root.option-popup-root {
  flex: none;
  width: 500px;
  padding: 30px;

  .option {
    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .table-title {
        font-size: 20px;
        font-weight: 600;
      }

      .update-option {
        width: fit-content;
        padding: 10px 20px;
        font-size: 16px;
      }
    }

    .body-section {
      min-height: fit-content;
      display: flex;
      flex-direction: column;

      .label {
        margin: 10px 0;
        font-size: 14px;
      }

      .opt,
      .optName {
        border: 1px solid #e5e5ec;
        height: 38px;
        padding: 5px;
        background-color: transparent;

        &:focus {
          border-color: #1379d8;
        }
      }

      .opt-wrapper {
        display: flex;
        align-items: center;
        gap: 3px;

        .opt {
          margin: 5px 0;
          flex: 1;
        }

        .delete-opt-btn {
          background-color: #fff;
          border: 1px solid #dc0000;
          color: #dc0000;
          padding: 5px 10px;
          height: 40px;
          border-radius: 4px;
        }
      }

      .add-option-btn {
        background-color: #661394;
        height: 40px;
        color: #fff;
      }
    }
  }
}
