.main-navitaion-root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.main-navitaion-root .content {
  display: flex;
  flex: 1;
  height: 100vh;
  overflow-y: scroll;
}
.main-navitaion-root .popup-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.368);
}/*# sourceMappingURL=main.navigation.styles.css.map */