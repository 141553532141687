.content-root.calc-root {
  min-height: 100vh;
  height: fit-content;

  .table-container {
    .setting-section {
      align-self: flex-end;
      display: flex;
      align-items: center;
      padding: 10px 36px;
      border-radius: 8px;

      label {
        font-size: 18px;
        color: #262626;
      }

      .input-container {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 16px 24px;
        height: 50px;
        border-radius: 4px;
        margin: 0 10px;
        font-size: 18px;
        background-color: #ffffff;

        input {
          width: 80px;
          flex: 1;
          font-size: 18px;
          text-align: right;
        }
      }
      & > .primary-btn {
        height: 50px;
        font-size: 16px;
        padding: 16px 24px;
        width: fit-content;
      }
    }

    .search-section {
      .csearch-root {
        flex: 1;
        .csearch-input-container {
          width: auto;
        }
      }

      .duration-search-container {
        display: flex;
        gap: 10px;

        .input-wrapper {
          display: flex;
          gap: 10px;
          align-items: center;

          .date-input {
            height: 48px;
            border-radius: 4px;
            padding: 0 10px;
          }
        }

        .apply-button {
          border-radius: 4px;
          padding: 0 10px;
          background-color: #661394;
          color: #fff;
          font-size: 18px;
        }
      }
    }
  }

  .row {
    .item.settleable {
      color: #661394;
      font-weight: 600;
    }

    .item.disable {
      color: #c06312;
      font-weight: 600;
    }

    .item.done {
      color: #000000;
    }
  }
}
