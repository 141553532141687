.content-root.order {
  min-height: 100vh;
  height: fit-content;

  .search-section {
    .duration-search-container {
      display: flex;
      gap: 10px;

      .input-wrapper {
        display: flex;
        gap: 10px;
        align-items: center;

        .date-input {
          height: 48px;
          border-radius: 4px;
          padding: 0 10px;
        }
      }

      .apply-button {
        border-radius: 4px;
        padding: 0 10px;
        background-color: #661394;
        color: #fff;
        font-size: 18px;
      }
    }
  }
}
