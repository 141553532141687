$default-box-color: #e5e5ec;
$focus-color: #1379d8;
$error-color: #dc0000;
$box-background-color: #f1f1f5;
$hover-color: rgb(19, 121, 216);
$secondary-text-color: #767676;
$font-black: #191919;

* {
  margin: 0;
  padding: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  // Hide Scroll Bar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
}
