.content .point-root {
  padding: 26px;
}
.content .point-root .header-container {
  display: flex;
}
.content .point-root .header-container .left-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 150px;
  flex: 1;
}
.content .point-root .header-container .left-wrapper .search-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  gap: 20px;
}
.content .point-root .header-container .left-wrapper .search-container .row {
  display: flex;
  gap: 20px;
}
.content .point-root .header-container .left-wrapper .search-container .row .admin-checkbox {
  display: flex;
  gap: 10px;
}
.content .point-root .header-container .left-wrapper .search-container .row .admin-checkbox span {
  font-size: 18px;
}
.content .point-root .header-container .left-wrapper .search-container .row .admin-checkbox input#checkbox {
  width: 20px;
  height: 20px;
}
.content .point-root .header-container .left-wrapper .search-container .input-container {
  display: flex;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
}
.content .point-root .header-container .left-wrapper .search-container .input-container .input-box {
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 16px;
  padding: 0 16px;
}
.content .point-root .header-container .left-wrapper .search-container .input-container .input-box.search {
  width: 360px;
}
.content .point-root .header-container .left-wrapper .search-container .input-container .input-button {
  width: 48px;
  cursor: pointer;
}
.content .point-root .header-container .left-wrapper .search-container .input-container .input-button img {
  width: 16px;
}
.content .point-root .header-container .left-wrapper .search-container .date-box {
  display: flex;
  align-items: center;
  gap: 10px;
}
.content .point-root .header-container .left-wrapper .search-container .date-box .date-title {
  font-size: 16px;
  font-weight: 500;
  color: #464255;
}
.content .point-root .header-container .left-wrapper .search-container .date-box .date-input {
  height: 48px;
  padding: 12px 16px;
  border-radius: 4px;
}
.content .point-root .header-container .right-wrapper {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.content .point-root .header-container .right-wrapper .setting-card-container {
  display: flex;
  flex-direction: column;
}
.content .point-root .header-container .right-wrapper .setting-card-container .setting-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 580px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 15px 24px;
  margin-bottom: 18px;
}
.content .point-root .header-container .right-wrapper .setting-card-container .setting-card .setting-card-title {
  flex: 1;
  font-size: 20px;
  font-weight: 600;
  color: #464255;
}
.content .point-root .header-container .right-wrapper .setting-card-container .setting-card .setting-card-input-box {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #464255;
  font-size: 20px;
  font-weight: 600;
}
.content .point-root .header-container .right-wrapper .setting-card-container .setting-card .setting-card-input-box .setting-card-input {
  height: 42px;
  width: 40px;
  text-align: center;
  border: solid 1px rgba(177, 177, 177, 0.6941176471);
  color: #464255;
  font-size: 20px;
  font-weight: 600;
  margin-right: 4px;
}
.content .point-root .header-container .right-wrapper .setting-card-container .setting-card .setting-button {
  flex: 1;
  width: 200px;
  height: 52px;
  max-width: 14px 65px;
  background-color: #651d8d;
  border-radius: 4px;
  font-size: 20px;
  color: #ffffff;
}
.content .point-root .footer-section {
  margin-bottom: 26px;
}/*# sourceMappingURL=points.css.map */