.cpaging-root {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cpaging-root .move-btn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e7e8;
  color: #4a0b6d;
  cursor: pointer;
  margin: 0px 3px;
}
.cpaging-root .number-btn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d8d8d8;
  background: #ffffff;
  cursor: pointer;
  margin: 0px 5px;
}
.cpaging-root .number-btn.current {
  background: #4a0b6d;
  color: #ffffff;
}/*# sourceMappingURL=cpaging.styles.css.map */