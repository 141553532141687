.cody-popup-root {
  height: 80vh;
  max-width: 1000px;
  background-color: #fff;
  overflow-y: auto;
}
.cody-popup-root .table-container.cody .product-image {
  width: 170px;
  height: 110px;
  background-color: #828282;
}
.cody-popup-root .table-container.cody .select-cody {
  width: 100px;
  font-size: 16px;
}/*# sourceMappingURL=cody.popup.styles.css.map */