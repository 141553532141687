$default-box-color: #e5e5ec;
$focus-color: #1379d8;
$error-color: #dc0000;
$box-background-color: #fff;
$secondary-background-color: #f9f9f9;
$hover-color: rgb(19, 121, 216);
$secondary-text-color: #767676;
$font-black: #191919;

.cselect-root {
  position: relative;
  margin: 0px;

  &.disabled {
    pointer-events: none;
    .cselect-input {
      background-color: #ccc;
    }
  }

  .cselect-input {
    width: calc((100vw - 400px) / 3);
    height: 48px;
    border-radius: 4px;
    border: 1px solid $default-box-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 16px;
    background-color: $box-background-color;
    cursor: pointer;

    &.focus {
      border: 1px solid $focus-color;
      background-color: $box-background-color;
    }

    .cselect-label {
      display: block;
      color: $font-black;
      flex: 1;
      font-size: 16px;
    }

    .cselect-under-arrow {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 10px;
        height: 5px;
      }
    }
  }

  .cselect-option {
    position: absolute;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid $default-box-color;
    cursor: pointer;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
    transition-duration: 300ms;
    transition-property: max-height;
    z-index: 999;
    margin-top: 4px;
    overflow: hidden;
    display: flex;
    gap: 10px;

    &.hide {
      max-height: 0px;
      overflow: hidden;
    }

    &.show {
      max-height: 200px;
    }

    .section {
      max-height: 200px;
      overflow-y: scroll;
      border-radius: 4px;
    }

    .cselect-option-item {
      width: calc((100vw - 400px) / 3);
      height: 42px;
      padding: 11px 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $font-black;
      font-size: 14px;
      background-color: $secondary-background-color;

      &:hover {
        background: rgba(19, 121, 216, 0.05);
        color: $font-black;
      }

      &.selected {
        color: $font-black;
      }
    }
  }
}
