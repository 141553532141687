.notice-root.content-root {
  height: fit-content;
  min-height: 100vh;

  .body-section {
    display: flex;
    flex-direction: column;

    .row.content {
      flex: 1;
      min-height: auto;
      align-items: unset;
      display: flex;

      #notice-detail-content {
        flex: 1;
        resize: none;
        border: 1px solid #e5e5ec;
        padding: 15px;
        font-size: 15px;
        border-radius: 4px;
        overflow-y: auto;
      }
    }
  }
}

.notice-event-root.content-root {
  height: fit-content;
  min-height: 100vh;
}
