.faq-root.content-root {
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
}
.faq-root.content-root .body-section {
  display: flex;
  flex-direction: column;
}
.faq-root.content-root .body-section .title-input {
  width: 100%;
  height: 48px;
  font-size: 15px;
  padding-left: 12px;
  padding-right: 22px;
  border: 1px solid #e5e5ec;
}
.faq-root.content-root .body-section .row.content {
  flex: 1;
  min-height: auto;
  align-items: unset;
  display: flex;
}
.faq-root.content-root .body-section .row.content #event-detail-content {
  flex: 1;
  resize: none;
  border: 1px solid #e5e5ec;
  padding: 15px;
  font-size: 15px;
  border-radius: 4px;
  overflow-y: auto;
}/*# sourceMappingURL=faq.detail.styles.css.map */