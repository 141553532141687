.content .qr-point-root {
  padding: 26px;
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}
.content .qr-point-root .search-container {
  display: flex;
  align-items: center;
  margin-top: 24px;
  gap: 20px;
}
.content .qr-point-root .search-container .input-container {
  display: flex;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
}
.content .qr-point-root .search-container .input-container .input-box {
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 16px;
  padding: 0 16px;
}
.content .qr-point-root .search-container .input-container .input-box.search {
  width: 360px;
}
.content .qr-point-root .search-container .input-container .input-button {
  width: 48px;
  cursor: pointer;
}
.content .qr-point-root .search-container .input-container .input-button img {
  width: 16px;
}
.content .qr-point-root .search-container .date-box {
  display: flex;
  align-items: center;
  gap: 10px;
}
.content .qr-point-root .search-container .date-box .date-title {
  font-size: 16px;
  font-weight: 500;
  color: #464255;
}
.content .qr-point-root .search-container .date-box .date-input {
  height: 48px;
  padding: 12px 16px;
  border-radius: 4px;
}
.content .qr-point-root .body-section .row {
  cursor: default;
}
.content .qr-point-root .body-section .row .item .qr-point-btn {
  background-color: #fff;
  border: 1px solid #661394;
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 16px;
  color: #661394;
}/*# sourceMappingURL=qr.point.styles.css.map */