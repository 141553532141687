* {
  margin: 0;
  padding: 0;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  -webkit-padding-before: 0;
          padding-block-start: 0;
  -webkit-padding-after: 0;
          padding-block-end: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.ccheckbox-root {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ccheckbox-root .ccheckbox-icon-container {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
.ccheckbox-root .ccheckbox-icon-container .ccheckbox-icon {
  width: 24px;
  height: 24px;
  background-color: #e5e5ec;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ccheckbox-root .ccheckbox-icon-container .ccheckbox-icon.disabled {
  background: #e1e4e6;
  border: 2px solid #f1f1f5;
}
.ccheckbox-root .ccheckbox-icon-container .ccheckbox-icon.round {
  border-radius: 24px;
}
.ccheckbox-root .ccheckbox-icon-container .ccheckbox-icon.square {
  border-radius: 4px;
}
.ccheckbox-root .ccheckbox-icon-container .ccheckbox-icon.fill {
  background-color: #1379d8;
  border: 2px solid #1379d8;
}
.ccheckbox-root .ccheckbox-icon-container .ccheckbox-icon.border {
  background-color: #ffffff;
  border: 2px solid #1379d8;
}
.ccheckbox-root .ccheckbox-icon-container .ccheckbox-icon.unchecked {
  border: 2px solid #e5e5ec;
  background-color: #ffffff;
}
.ccheckbox-root .ccheckbox-icon-container .ccheckbox-icon img {
  width: 12px;
  height: 8px;
}
.ccheckbox-root .ccheckbox-label {
  color: #767676;
  font-size: 15px;
}
.ccheckbox-root .ccheckbox-label.checked {
  color: #111111;
}
.ccheckbox-root .ccheckbox-label.disabled {
  color: #999999;
}/*# sourceMappingURL=ccheckbox.styles.css.map */