.cselect-root {
  position: relative;
  margin: 0px;
}
.cselect-root.disabled {
  pointer-events: none;
}
.cselect-root.disabled .cselect-input {
  background-color: #ccc;
}
.cselect-root .cselect-input {
  width: 123px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #e5e5ec;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px;
  background-color: #fff;
  cursor: pointer;
}
.cselect-root .cselect-input.focus {
  border: 1px solid #1379d8;
  background-color: #fff;
}
.cselect-root .cselect-input .cselect-label {
  display: block;
  color: #191919;
  flex: 1;
  font-size: 16px;
}
.cselect-root .cselect-input .cselect-under-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cselect-root .cselect-input .cselect-under-arrow img {
  width: 10px;
  height: 5px;
}
.cselect-root .cselect-option {
  position: absolute;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #e5e5ec;
  cursor: pointer;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
  transition-duration: 300ms;
  transition-property: max-height;
  z-index: 999;
  margin-top: 4px;
  overflow: hidden;
  display: flex;
  padding: 10px;
  gap: 10px;
}
.cselect-root .cselect-option.hide {
  max-height: 0px;
  overflow: hidden;
}
.cselect-root .cselect-option.show {
  max-height: 200px;
}
.cselect-root .cselect-option .section {
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 4px;
}
.cselect-root .cselect-option .cselect-option-item {
  width: 120px;
  height: 42px;
  padding: 11px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #191919;
  font-size: 14px;
  background-color: #f9f9f9;
}
.cselect-root .cselect-option .cselect-option-item:hover {
  background: rgba(19, 121, 216, 0.05);
  color: #191919;
}
.cselect-root .cselect-option .cselect-option-item.selected {
  color: #191919;
}/*# sourceMappingURL=cselect.module.css.map */