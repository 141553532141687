.category.content-root {
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
}
.category.content-root .item input {
  flex: 1;
  width: 80px;
  text-align: center;
  font-size: 16px;
  padding: 10px 0;
  border: 1px solid #661394;
}/*# sourceMappingURL=category.styles.css.map */