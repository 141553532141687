.cfile-input-root {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 12px 0px;

  .cfile-label-container {
    .cfile-label {
      font-size: 14px;
      color: #191919;
      font-weight: 500;
      margin-bottom: 4px;
    }
  }

  .cfile-select-container {
    display: flex;
    flex-direction: row;

    .cfile-input-file-name {
      flex: 1;
      border: 1px solid #e5e5ec;
      padding-left: 16px;
      display: flex;
      align-items: center;
      font-size: 15px;
      color: #191919;
    }

    .select-btn {
      width: 100px;
    }
  }
}
