.content-root.order-detail .body-section.detail {
  background-color: #fff;
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 30px;
  margin-top: 20px;
}
.content-root.order-detail .body-section.detail .list-item {
  display: flex;
  gap: 20px;
}
.content-root.order-detail .body-section.detail .list-item .label {
  color: #262626;
  font-weight: 600;
  width: 100px;
}
.content-root.order-detail .body-section.list {
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.content-root.order-detail .body-section.list .row.account {
  flex-direction: column;
  padding: 30px 0;
  align-items: flex-end;
  font-size: 18px;
  gap: 20px;
}
.content-root.order-detail .body-section.list .row.account:not(:last-child) {
  border-bottom: 1px solid #661394;
}
.content-root.order-detail .body-section.list .row.account > .item {
  flex: none;
  padding: 0 20px;
}/*# sourceMappingURL=order.detail.styles.css.map */