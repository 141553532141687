.content-root.coupon {
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}
.content-root.coupon .title-container {
  display: flex;
  justify-content: space-between;
}
.content-root.coupon .title-container .add-coupon-button {
  background-color: #651d8d;
  width: 200px;
  border-radius: 4px;
  color: #ffffff;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}
.content-root.coupon .table-container .header-section .item.condition {
  flex: 2;
}
.content-root.coupon .table-container .body-section .row .item.condition {
  flex: 2;
}/*# sourceMappingURL=coupon.styles.css.map */