.content-root.user {
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
}
.content-root.user .search-section {
  display: flex;
  align-items: center;
  gap: 20px;
}
.content-root.user .search-section .input-container {
  display: flex;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
}
.content-root.user .search-section .input-container .input-box {
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 16px;
  padding: 0 16px;
}
.content-root.user .search-section .input-container .input-box.search {
  width: 360px;
}
.content-root.user .search-section .input-container .input-button {
  width: 48px;
  cursor: pointer;
}
.content-root.user .search-section .input-container .input-button img {
  width: 16px;
}
.content-root.user .search-section .select-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
}
.content-root.user .search-section .select-container .select-box {
  cursor: pointer;
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 48px;
  padding: 0 40px 0 16px;
  outline: 0;
  font-size: 16px;
}
.content-root.user .search-section .search-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}
.content-root.user .search-section .search-container .user-search-button {
  background-color: #661394;
  width: 100px;
  border-radius: 12px;
  color: #ffffff;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}/*# sourceMappingURL=user.styles.css.map */