$main: #661394;

.content-root.partners-coupon {
  .table-container {
    .title-section {
      display: flex;
      justify-content: space-between;

      .add-coupon-button {
        background-color: $main;
        padding: 5px 16px;
        border-radius: 4px;
        color: #ffff;
        cursor: pointer;
      }
    }
    .header-section {
      width: 100%;
    }

    .body-section {
      flex-direction: column;

      .row {
        display: flex;
        gap: 10px;

        .item {
          flex: 1;

          input {
            text-align: center;
            font-size: 14px;
            border: 1px solid #e5e5ec;
            background-color: #ffff;
            padding: 10px 0;

            &:focus {
              border: 1px solid rgb(19, 121, 216);
            }
          }

          &.button-wrapper {
            border: 0;
            .button {
              padding: 10px 16px;
              border-radius: 4px;
              cursor: pointer;

              &.delete {
                border: 1px solid #ff0000;
                color: #ff0000;
                background-color: #ffff;
              }

              &.save {
                background-color: $main;
                color: #ffff;
              }
            }
          }
        }
      }
    }
  }
}
