.content-root.partners-detail {
  height: -moz-fit-content;
  height: fit-content;
}
.content-root.partners-detail .button-container.signUp .back,
.content-root.partners-detail .button-container.update .back {
  margin-right: auto;
  width: 100px;
}
.content-root.partners-detail .content-root {
  padding: 0;
}
.content-root.partners-detail .content-root .table-container {
  height: -moz-fit-content;
  height: fit-content;
}
.content-root.partners-detail .content-root .table-container .table-title {
  font-size: 20px;
  font-weight: 600;
  margin: 12px 0;
}
.content-root.partners-detail .content-root .table-container .body-section.view {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.content-root.partners-detail .content-root .table-container .store-file-section {
  display: flex;
  padding: 20px;
  gap: 20px;
}
.content-root.partners-detail .content-root .table-container .store-file-section.view {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.content-root.partners-detail .content-root .table-container .store-file-section .business-file-section {
  display: flex;
  flex-direction: column;
  width: calc((100vw - 400px) / 3);
  min-width: 400px;
}
.content-root.partners-detail .content-root .table-container .store-file-section .business-file-section .cfile-input-file-name {
  background-color: white;
}
.content-root.partners-detail .content-root .table-container .store-file-section .business-file-section .file-download-btn {
  color: #661394;
  margin-bottom: 20px;
  pointer-events: all;
  cursor: pointer;
}
.content-root.partners-detail .content-root .table-container .store-file-section .rest-day-section {
  flex: 1;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.content-root.partners-detail .content-root .table-container .store-file-section .rest-day-section .week-container {
  display: flex;
  gap: 10px;
}
.content-root.partners-detail .content-root .table-container .store-file-section .rest-day-section .item {
  cursor: pointer;
  padding: 5px 10px;
  background-color: #ffffff;
  border: 1px solid #b1b1b1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-root.partners-detail .content-root .table-container .store-file-section .rest-day-section .item.selected {
  background-color: #707070;
}
.content-root.partners-detail .content-root .table-container .store-file-section .rest-day-section .item.week,
.content-root.partners-detail .content-root .table-container .store-file-section .rest-day-section .item.holidays {
  width: 80px;
}
.content-root.partners-detail .content-root .table-container .body-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.content-root.partners-detail .content-root .table-container .body-section .row {
  height: -moz-fit-content;
  height: fit-content;
}
.content-root.partners-detail .content-root .table-container .body-section .row .cselect-root {
  width: calc((100vw - 400px) / 3);
  margin: 0;
}
.content-root.partners-detail .content-root .table-container .body-section .row .cselect-root .cselect-input {
  width: 100%;
  max-width: 400px;
}
.content-root.partners-detail .content-root .table-container .body-section .row .partners-location-root .label {
  font-size: 14px;
  color: #111111;
  font-weight: 500;
  margin-bottom: 2px;
}
.content-root.partners-detail .content-root .table-container .body-section .row .partners-location-root .location-input {
  width: calc((100vw - 400px) / 3);
  display: flex;
  flex-direction: row;
  height: 48px;
  border: 1px solid #e5e5ec;
  align-items: center;
  padding: 16px;
}
.content-root.partners-detail .content-root .table-container .body-section .row .item {
  display: flex;
  flex-direction: column;
}
.content-root.partners-detail .content-root .table-container .body-section .row .item .shop-category-label {
  margin-right: auto;
  font-size: 14px;
  color: #111111;
  font-weight: 500;
  margin-bottom: 2px;
}/*# sourceMappingURL=partners.detail.styles.css.map */