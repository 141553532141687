.gnb-root {
  width: 260px;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #651d8d;
  overflow-y: scroll;
}
.gnb-root .logo-container {
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 24px;
}
.gnb-root .logo-container .logo-desc {
  align-self: flex-start;
  color: #f2f3f5;
  margin-left: 5px;
  font-weight: 500;
}
.gnb-root .gnb-item-container {
  flex: 1;
  cursor: pointer;
  width: 100%;
  min-height: 80px;
  color: #f2f3f5;
  font-size: 22px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  border-left: 8px solid #651d8d;
}
.gnb-root .gnb-item-container:hover {
  background-color: #4a0b6d;
  border-left-color: #4a0b6d;
  color: #f2f3f5;
}
.gnb-root .gnb-item-container.selected {
  background-color: #4a0b6d;
  border-left-color: #f2f3f5;
  color: #f2f3f5;
}
.gnb-root .gnb-item-container .gnb-item-label {
  background-color: transparent;
}
.gnb-root .logout-btn {
  cursor: pointer;
  width: 200px;
  min-height: 60px;
  font-size: 20px;
  margin: 32px 0;
  background-color: #4a0b6d;
  color: #ffffff;
  padding: 10px;
  border-radius: 4px;
}/*# sourceMappingURL=gnb.styles.css.map */