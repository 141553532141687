$default-box-color: #e5e5ec;
$focus-color: #1379d8;
$error-color: #dc0000;
$box-background-color: #f1f1f5;
$hover-color: rgb(19, 121, 216);
$secondary-text-color: #767676;
$font-black: #191919;

* {
  margin: 0;
  padding: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

.csearch-root {
  margin: 0px 12px;

  .csearch-input-container {
    position: relative;
    width: 500px;
    height: 48px;
    display: flex;
    flex-direction: row;
    border: 1px solid #e5e5ec;
    border-radius: 4px;
    overflow: hidden;

    .csearch-input {
      flex: 1;
      height: 48px;
      font-size: 15px;
      padding: 16px;
      padding-right: 34px;
    }

    .csearch-delete-btn {
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 64px;
      width: 18px;
      height: 18px;

      img {
        width: 18px;
        height: 18px;
      }
    }

    .csearch-search-btn {
      cursor: pointer;
      width: 48px;
      height: 48px;
      background: #f1f1f5;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
