.content-root.order-detail {
  .body-section.detail {
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 30px;
    margin-top: 20px;

    .list-item {
      display: flex;
      gap: 20px;
      .label {
        color: #262626;
        font-weight: 600;
        width: 100px;
      }

      .item {
      }
    }
  }

  .body-section.list {
    min-height: fit-content;

    .row {
      &.account {
        flex-direction: column;
        padding: 30px 0;
        align-items: flex-end;
        font-size: 18px;
        gap: 20px;

        &:not(:last-child) {
          border-bottom: 1px solid #661394;
        }

        & > .item {
          flex: none;
          padding: 0 20px;
        }
      }
    }
  }
}
