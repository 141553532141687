$main: #661394;

.content-root.user {
  height: fit-content;
  min-height: 100vh;

  .search-section {
    display: flex;
    align-items: center;
    gap: 20px;

    .input-container {
      display: flex;
      background-color: #ffffff;
      border-radius: 4px;
      overflow: hidden;

      .input-box {
        display: flex;
        align-items: center;

        height: 48px;
        font-size: 16px;
        padding: 0 16px;

        &.search {
          width: 360px;
        }
      }

      .input-button {
        width: 48px;
        cursor: pointer;
        img {
          width: 16px;
        }
      }
    }

    .select-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: #ffffff;
      border-radius: 4px;

      .select-box {
        cursor: pointer;
        -o-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 48px;
        padding: 0 40px 0 16px;
        outline: 0;
        font-size: 16px;
      }
    }

    .search-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 15px;
      margin-top: 20px;

      .user-search-button {
        background-color: $main;
        width: 100px;
        border-radius: 12px;
        color: #ffffff;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}
