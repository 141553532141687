.sale-item-root.content-root {
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}
.sale-item-root.content-root .csearch-root {
  flex: 1;
}
.sale-item-root.content-root .csearch-root .csearch-input-container {
  width: auto;
  flex: 1;
}
.sale-item-root.content-root .setting-section {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 36px;
  border-radius: 8px;
}
.sale-item-root.content-root .setting-section label {
  font-size: 18px;
  color: #262626;
}
.sale-item-root.content-root .setting-section .input-container {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  border: solid 1px #b1b1b1;
  border-radius: 8px;
  margin: 0 10px;
  font-size: 18px;
}
.sale-item-root.content-root .setting-section .input-container input {
  width: 80px;
  flex: 1;
  font-size: 18px;
  text-align: right;
}
.sale-item-root.content-root .setting-section .primary-btn {
  font-size: 16px;
  padding: 16px 24px;
  width: -moz-fit-content;
  width: fit-content;
}
.sale-item-root.content-root .body-section .row .item .store-image {
  width: 170px;
  height: 110px;
}/*# sourceMappingURL=sale.item.styles.css.map */