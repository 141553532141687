.add-coupon-popup-root {
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 38px 36px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .limit-date-wrapper {
    display: flex;
    gap: 20px;
    .checkbox-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 300;
      input {
        width: 18px;
        height: 18px;
      }
    }
  }

  .coupon-input-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .button-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;

    .button {
      flex: 1;
      padding: 15px 0;
      font-size: 18px;
    }

    .cancel-button {
    }

    .save-button {
      background-color: #661394;
      color: #fff;
    }
  }
}
