$main: #661394;

.content-root.coupon-detail {
  gap: 40px;
  .title-container {
    display: flex;
    justify-content: space-between;
    .title-item {
    }

    .button-wrapper {
      display: flex;
      gap: 10px;
    }
  }

  .body-section {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 38px 36px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .limit-date-wrapper {
      display: flex;
      gap: 20px;
      .checkbox-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 300;
        input {
          width: 18px;
          height: 18px;
        }
      }
    }

    .coupon-input-wrapper {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}
