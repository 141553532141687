.cmulti-file-input-root {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
}
.cmulti-file-input-root .cmulti-file-select-btn {
  width: 160px;
  height: 100px;
  margin-right: 20px;
  background-color: #e4e4e4;
  border-radius: 4px;
  cursor: pointer;
}
.cmulti-file-input-root .image-preview-container {
  cursor: pointer;
  margin-right: 20px;
  width: 160px;
  height: 100px;
  background-color: #e4e4e4;
  border-radius: 4px;
}
.cmulti-file-input-root .image-preview-container:hover {
  opacity: 0.3;
}
.cmulti-file-input-root .image-preview-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}/*# sourceMappingURL=cmulti.file.input.styles.css.map */