.content-root.partners-detail {
  height: fit-content;

  .button-container.signUp,
  .button-container.update {
    .back {
      margin-right: auto;
      width: 100px;
    }
  }

  .content-root {
    padding: 0;

    .table-container {
      height: fit-content;

      .table-title {
        font-size: 20px;
        font-weight: 600;
        margin: 12px 0;
      }

      .body-section.view {
        pointer-events: none;
        user-select: none;
      }

      .store-file-section {
        display: flex;
        padding: 20px;
        gap: 20px;

        &.view {
          pointer-events: none;
          user-select: none;
        }

        .business-file-section {
          display: flex;
          flex-direction: column;
          width: calc((100vw - 400px) / 3);
          min-width: 400px;
          .cfile-input-file-name {
            background-color: #ffff;
          }

          .file-download-btn {
            color: #661394;
            margin-bottom: 20px;
            pointer-events: all;
            cursor: pointer;
          }
        }

        .rest-day-section {
          flex: 1;
          min-width: fit-content;
          display: flex;
          flex-direction: column;

          gap: 10px;

          .week-container {
            display: flex;
            gap: 10px;
          }

          .item {
            cursor: pointer;
            padding: 5px 10px;
            background-color: #ffffff;
            border: 1px solid #b1b1b1;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.selected {
              background-color: #707070;
            }
          }

          .item.week,
          .item.holidays {
            width: 80px;
          }
        }
      }

      .body-section {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        min-height: fit-content;
        .row {
          height: fit-content;

          .cselect-root {
            width: calc((100vw - 400px) / 3);
            margin: 0;

            .cselect-input {
              width: 100%;
              max-width: 400px;
            }
          }
          .partners-location-root {
            .label {
              font-size: 14px;
              color: #111111;
              font-weight: 500;
              margin-bottom: 2px;
            }

            .location-input {
              width: calc((100vw - 400px) / 3);
              display: flex;
              flex-direction: row;
              height: 48px;
              border: 1px solid #e5e5ec;
              align-items: center;
              padding: 16px;
            }
          }
          .item {
            display: flex;
            flex-direction: column;
            .shop-category-label {
              margin-right: auto;
              font-size: 14px;
              color: #111111;
              font-weight: 500;
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
}
