.content-root.signup-request-detail-root {
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}
.content-root.signup-request-detail-root .table-title {
  font-size: 20px;
  font-weight: 600;
  margin: 12px 0;
}
.content-root.signup-request-detail-root .body-section {
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px 30px;
  margin-top: 20px;
  border-radius: 4px;
}
.content-root.signup-request-detail-root .body-section .row {
  cursor: default !important;
}
.content-root.signup-request-detail-root .body-section .row.image {
  grid-column-start: 1;
  grid-column-end: 4;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.content-root.signup-request-detail-root .body-section .row.image img {
  width: 240px;
  height: 180px;
}
.content-root.signup-request-detail-root .body-section .row .item.only-view {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}
.content-root.signup-request-detail-root .body-section .row .item.only-view .label {
  width: 100px;
  font-weight: 600;
  text-align: left;
  word-break: keep-all;
}
.content-root.signup-request-detail-root .store-file-section {
  display: flex;
  padding: 20px;
  gap: 50px;
}
.content-root.signup-request-detail-root .store-file-section .business-file-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}
.content-root.signup-request-detail-root .store-file-section .rest-day-section {
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.content-root.signup-request-detail-root .store-file-section .rest-day-section .week-container {
  display: flex;
  gap: 10px;
}
.content-root.signup-request-detail-root .store-file-section .rest-day-section .item {
  cursor: default;
  padding: 5px 10px;
  background-color: #ffffff;
  border: 1px solid #b1b1b1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-root.signup-request-detail-root .store-file-section .rest-day-section .item.selected {
  background-color: #707070;
}
.content-root.signup-request-detail-root .store-file-section .rest-day-section .item.week,
.content-root.signup-request-detail-root .store-file-section .rest-day-section .item.holidays {
  width: 80px;
}/*# sourceMappingURL=signup.request.styles.css.map */