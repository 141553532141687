@import './cinput.styles.scss';

.ccheckbox-root {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  .ccheckbox-icon-container {
    width: 24px;
    height: 24px;
    margin-right: 6px;

    .ccheckbox-icon {
      width: 24px;
      height: 24px;
      background-color: $default-box-color;
      display: flex;
      justify-content: center;
      align-items: center;

      &.disabled {
        background: #e1e4e6;
        border: 2px solid #f1f1f5;
      }

      &.round {
        border-radius: 24px;
      }

      &.square {
        border-radius: 4px;
      }

      &.fill {
        background-color: $focus-color;
        border: 2px solid #1379d8;
      }

      &.border {
        background-color: #ffffff;
        border: 2px solid #1379d8;
      }

      &.unchecked {
        border: 2px solid #e5e5ec;
        background-color: #ffffff;
      }

      img {
        width: 12px;
        height: 8px;
      }
    }
  }

  .ccheckbox-label {
    color: #767676;
    font-size: 15px;

    &.checked {
      color: #111111;
    }

    &.disabled {
      color: #999999;
    }
  }
}
