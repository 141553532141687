.cmulti-file-input-root {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;

  .cmulti-file-select-btn {
    width: 160px;
    height: 100px;
    margin-right: 20px;
    background-color: #e4e4e4;
    border-radius: 4px;
    cursor: pointer;
  }

  .image-preview-container {
    cursor: pointer;
    margin-right: 20px;
    width: 160px;
    height: 100px;
    background-color: #e4e4e4;
    border-radius: 4px;

    &:hover {
      opacity: 0.3;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}
