$black: #464255;
$main: #4a0b6d;
$main200: #651d8d;

.content {
  .point-root {
    padding: 26px;
    .header-container {
      display: flex;
      .left-wrapper {
        display: flex;
        flex-direction: column;
        margin-right: 150px;
        flex: 1;

        .search-container {
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
          gap: 20px;

          .row {
            display: flex;
            gap: 20px;

            .admin-checkbox {
              display: flex;
              gap: 10px;

              span {
                font-size: 18px;
              }

              input#checkbox {
                width: 20px;
                height: 20px;
              }
            }
          }

          .input-container {
            display: flex;
            background-color: #ffffff;
            border-radius: 4px;
            overflow: hidden;

            .input-box {
              display: flex;
              align-items: center;
              height: 48px;
              font-size: 16px;
              padding: 0 16px;

              &.search {
                width: 360px;
              }
            }

            .input-button {
              width: 48px;
              cursor: pointer;
              img {
                width: 16px;
              }
            }
          }
          .date-box {
            display: flex;
            align-items: center;
            gap: 10px;

            .date-title {
              font-size: 16px;
              font-weight: 500;
              color: $black;
            }
            .date-input {
              height: 48px;
              padding: 12px 16px;
              border-radius: 4px;
            }
          }
        }
      }
      .right-wrapper {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        .setting-card-container {
          display: flex;
          flex-direction: column;
          .setting-card {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            width: 580px;
            background-color: #ffffff;
            border-radius: 4px;
            padding: 15px 24px;
            margin-bottom: 18px;
            .setting-card-title {
              flex: 1;
              font-size: 20px;
              font-weight: 600;
              color: $black;
            }
            .setting-card-input-box {
              display: flex;
              flex: 1;
              justify-content: center;
              align-items: center;
              color: $black;
              font-size: 20px;
              font-weight: 600;
              .setting-card-input {
                height: 42px;
                width: 40px;
                text-align: center;
                border: solid 1px #b1b1b1b1;
                color: $black;
                font-size: 20px;
                font-weight: 600;
                margin-right: 4px;
              }
            }

            .setting-button {
              flex: 1;
              width: 200px;
              height: 52px;
              max-width: 14px 65px;
              background-color: $main200;
              border-radius: 4px;
              font-size: 20px;
              color: #ffffff;
            }
          }
        }
      }
    }

    .footer-section {
      margin-bottom: 26px;
    }
  }
}
