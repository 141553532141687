@import './cinput.styles.scss';

.cunderline-adaptive-root {
  display: flex;
  flex-direction: column;
  height: 90px;
  margin-top: 4px;

  .cunderline-adaptive-input {
    width: 400px;
    position: relative;

    .cunderline-adaptive-disabled-input {
      width: 100%;
      height: 48px;
      font-size: 15px;
      padding-left: 12px;
      border-bottom: 1px solid $default-box-color;
      background-color: $box-background-color;
      color: $secondary-text-color;
      display: flex;
      align-items: center;
    }

    .cunderline-adaptive-input-icon {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 15px;
      left: 12px;
    }

    .cunderline-adaptive-input-input {
      width: 100%;
      height: 48px;
      font-size: 15px;
      padding-right: 22px;
      border-bottom: 1px solid $default-box-color;
    }

    .cunderline-adaptive-delete-btn {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 15px;
      right: 15px;

      img {
        width: 100%;
      }
    }

    .cunderline-adaptive-label {
      font-size: 14px;
      color: $focus-color;
      font-weight: 500;
      margin-bottom: 4px;
      transition-duration: 150ms;
      transition-property: font-size, background-color, color, top;

      &.active {
        position: absolute;
        background-color: #ffffff;
        color: $focus-color;
        font-size: 14px;
        top: -6px;
        left: 0px;
      }

      &.deactive {
        position: absolute;
        color: $secondary-text-color;
        background-color: transparent;
        font-size: 15px;
        top: 15px;
        left: 0px;
      }

      .cunderline-adaptive-input-required {
        color: $focus-color;
        vertical-align: middle;
      }
    }
  }

  .cunderline-adaptive-error-message {
    font-size: 12px;
    color: $error-color;
    margin-top: 8px;
  }
}
