.main-navitaion-root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;

  .content {
    display: flex;
    flex: 1;
    height: 100vh;
    overflow-y: scroll;
  }

  .popup-backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.368);
  }
}
