.content-root.option-popup-root {
  flex: none;
  width: 500px;
  padding: 30px;
}
.content-root.option-popup-root .option .title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-root.option-popup-root .option .title-wrapper .table-title {
  font-size: 20px;
  font-weight: 600;
}
.content-root.option-popup-root .option .title-wrapper .update-option {
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 20px;
  font-size: 16px;
}
.content-root.option-popup-root .option .body-section {
  min-height: -moz-fit-content;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
}
.content-root.option-popup-root .option .body-section .label {
  margin: 10px 0;
  font-size: 14px;
}
.content-root.option-popup-root .option .body-section .opt,
.content-root.option-popup-root .option .body-section .optName {
  border: 1px solid #e5e5ec;
  height: 38px;
  padding: 5px;
  background-color: transparent;
}
.content-root.option-popup-root .option .body-section .opt:focus,
.content-root.option-popup-root .option .body-section .optName:focus {
  border-color: #1379d8;
}
.content-root.option-popup-root .option .body-section .opt-wrapper {
  display: flex;
  align-items: center;
  gap: 3px;
}
.content-root.option-popup-root .option .body-section .opt-wrapper .opt {
  margin: 5px 0;
  flex: 1;
}
.content-root.option-popup-root .option .body-section .opt-wrapper .delete-opt-btn {
  background-color: #fff;
  border: 1px solid #dc0000;
  color: #dc0000;
  padding: 5px 10px;
  height: 40px;
  border-radius: 4px;
}
.content-root.option-popup-root .option .body-section .add-option-btn {
  background-color: #661394;
  height: 40px;
  color: #fff;
}/*# sourceMappingURL=option.popup.styles.css.map */