.content-root.calc-detail-root {
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}
.content-root.calc-detail-root .body-section.basic {
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  margin-top: 20px;
  border-radius: 4px;
}
.content-root.calc-detail-root .body-section.basic .row .item .label {
  display: inline-block;
  width: 100px;
  font-weight: 600;
}
.content-root.calc-detail-root .table-container .header-section {
  gap: 5px;
}
.content-root.calc-detail-root .table-container .header-section .item.count {
  flex: 0.5;
}
.content-root.calc-detail-root .table-container .body-section .store-row {
  padding-bottom: 60px;
  border-bottom: 1px solid #661394;
}
.content-root.calc-detail-root .table-container .body-section .store-row:last-child {
  border-bottom: 0;
}
.content-root.calc-detail-root .table-container .body-section .store-row .row {
  gap: 5px;
  cursor: default;
}
.content-root.calc-detail-root .table-container .body-section .store-row .row.account .name {
  font-weight: 700;
}
.content-root.calc-detail-root .table-container .body-section .store-row .row .item {
  flex: 1;
  text-align: center;
}
.content-root.calc-detail-root .table-container .body-section .store-row .row .item.count {
  flex: 0.5;
}
.content-root.calc-detail-root .table-container .body-section .store-row .row .item.calc, .content-root.calc-detail-root .table-container .body-section .store-row .row .item.cancel {
  font-weight: 600;
}
.content-root.calc-detail-root .table-container .body-section .store-row .row .item.settlement .input {
  border: 1px solid #e5e5ec;
  width: 100%;
  height: 40px;
  font-size: 16px;
  padding: 10px;
}
.content-root.calc-detail-root .table-container .body-section .store-row .row .item .settlement-btn {
  background-color: #fff;
  color: #661394;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.content-root.calc-detail-root .table-container .body-section .store-row .row .item .cancel-btn {
  background-color: #fff;
  color: #dc0000;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.content-root.calc-detail-root .table-container .body-section .store-row .row .item .calc-amount {
  width: 110px;
  height: 40px;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #e5e5ec;
}
.content-root.calc-detail-root .table-container .body-section .store-row .row .item .calc-amount:focus {
  border-color: #1379d8;
}/*# sourceMappingURL=calc.detail.styles.css.map */