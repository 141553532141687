.content-root.item-detail {
  min-height: 100vh;
  height: fit-content;

  .table-container.basic {
    .body-section {
      .input-section {
        display: flex;
        gap: 20px;
      }

      .column {
        flex: 1;
      }

      .row {
        select::-ms-expand {
          display: none;
        }

        .category {
          cursor: pointer;
          -o-appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: calc((100vw - 400px) / 3);
          height: 42px;
          border: 1px solid #e5e5ec;
          outline: 0;
          padding: 0 10px;
          border-radius: 4px;
          font-size: 16px;
        }

        .item.today-get {
          justify-content: flex-start;
        }

        .item.option {
          flex-direction: column;
          gap: 5px;

          .item-label {
            align-self: flex-start;
            font-size: 14px;
          }

          .add-option-btn {
            width: 100%;
            background-color: #661394;
            padding: 10px;
            color: #fff;
          }

          .update-option-btn {
            padding: 5px;
            background-color: #661394;
            color: #fff;
          }

          .delete-option-btn {
            padding: 5px;
            border: 1px solid #dc0000;
            background-color: #fff;
            color: #dc0000;
          }

          .option-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .option-item {
              display: flex;

              .opt-name {
                border: 1px solid #e5e5ec;
                width: 80px;
                height: 48px;
                padding: 5px;
                margin-right: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .opt {
                border: 1px solid #e5e5ec;
                flex: 3;
                height: 48px;
                padding: 5px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
              }
            }
          }
        }

        .item.description {
          flex-direction: column;
          gap: 2px;

          .item-label {
            width: 100%;
            align-self: flex-start;
            font-size: 14px;
          }

          .description-item-wrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
            margin-bottom: 20px;
            background-color: #e5e5ec;
            padding: 3px;

            .input-wrapper {
              display: flex;
              .description-input {
                width: 100%;
                border: 1px solid #fff;
                height: 80px;
                padding: 5px;
                resize: none;
              }

              .delete-description-btn {
                font-size: 30px;
                background-color: #fff;
                padding: 0 10px;
              }
            }

            .file-wrapper {
              display: flex;
              padding: 0 3px 3px;

              .label {
                flex: 1;
                font-weight: 300;
                font-size: 14px;
              }

              .preview-btn {
                flex: 1;
                background-color: transparent;
                color: #661394;
                text-align: right;
              }
            }
          }

          .add-description-btn {
            width: 100%;
            background-color: #661394;
            padding: 10px;
            color: #fff;
          }
        }
      }
    }
  }

  .table-container.stock {
    .body-section {
      min-height: fit-content;
    }
    .stock-input {
      width: 140px;
      border: 1px solid #e5e5ec;
      height: 48px;
      padding: 5px;
      text-align: center;
      font-size: 16px;
      &:focus {
        border-color: #1379d8;
      }
    }
  }

  .table-container.cody {
    .title-wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .table-title {
        font-size: 20px;
        font-weight: 600;
      }

      .add-cody {
        font-size: 16px;
        width: 150px;
      }
    }

    .product-image {
      width: 170px;
      height: 110px;
    }

    .delete-cody {
      width: 100px;
      font-size: 16px;
    }
  }
}
