$main: #4a0b6d;

.login-root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  .title {
    display: flex;
    align-items: center;
    color: $main;
    font-size: 30px;
    font-weight: 600;
    gap: 10px;

    .logo {
      width: 40px;
      left: -10px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  input {
    width: 400px;
    height: 48px;
    font-size: 16px;
    padding: 10px;
    border: 1px solid #e5e5ec;
  }

  .login-btn {
    cursor: pointer;
    width: 400px;
    height: 48px;
    font-size: 16px;
    background-color: $main;
    color: #ffffff;
  }
}
