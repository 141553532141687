$main: #4a0b6d;
$main200: #651d8d;

.content-root.coupon {
  min-height: 100vh;
  height: fit-content;

  .title-container {
    display: flex;
    justify-content: space-between;
    .title-item {
    }

    .add-coupon-button {
      background-color: $main200;
      width: 200px;
      border-radius: 4px;
      color: #ffffff;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .table-container {
    .header-section {
      .item.condition {
        flex: 2;
      }
    }

    .body-section {
      .row {
        .item.condition {
          flex: 2;
        }
      }
    }
  }
}
