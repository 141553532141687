.content-root.item-detail {
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}
.content-root.item-detail .table-container.basic .body-section .input-section {
  display: flex;
  gap: 20px;
}
.content-root.item-detail .table-container.basic .body-section .column {
  flex: 1;
}
.content-root.item-detail .table-container.basic .body-section .row select::-ms-expand {
  display: none;
}
.content-root.item-detail .table-container.basic .body-section .row .category {
  cursor: pointer;
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: calc((100vw - 400px) / 3);
  height: 42px;
  border: 1px solid #e5e5ec;
  outline: 0;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 16px;
}
.content-root.item-detail .table-container.basic .body-section .row .item.today-get {
  justify-content: flex-start;
}
.content-root.item-detail .table-container.basic .body-section .row .item.option {
  flex-direction: column;
  gap: 5px;
}
.content-root.item-detail .table-container.basic .body-section .row .item.option .item-label {
  align-self: flex-start;
  font-size: 14px;
}
.content-root.item-detail .table-container.basic .body-section .row .item.option .add-option-btn {
  width: 100%;
  background-color: #661394;
  padding: 10px;
  color: #fff;
}
.content-root.item-detail .table-container.basic .body-section .row .item.option .update-option-btn {
  padding: 5px;
  background-color: #661394;
  color: #fff;
}
.content-root.item-detail .table-container.basic .body-section .row .item.option .delete-option-btn {
  padding: 5px;
  border: 1px solid #dc0000;
  background-color: #fff;
  color: #dc0000;
}
.content-root.item-detail .table-container.basic .body-section .row .item.option .option-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.content-root.item-detail .table-container.basic .body-section .row .item.option .option-list .option-item {
  display: flex;
}
.content-root.item-detail .table-container.basic .body-section .row .item.option .option-list .option-item .opt-name {
  border: 1px solid #e5e5ec;
  width: 80px;
  height: 48px;
  padding: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-root.item-detail .table-container.basic .body-section .row .item.option .option-list .option-item .opt {
  border: 1px solid #e5e5ec;
  flex: 3;
  height: 48px;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.content-root.item-detail .table-container.basic .body-section .row .item.description {
  flex-direction: column;
  gap: 2px;
}
.content-root.item-detail .table-container.basic .body-section .row .item.description .item-label {
  width: 100%;
  align-self: flex-start;
  font-size: 14px;
}
.content-root.item-detail .table-container.basic .body-section .row .item.description .description-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 20px;
  background-color: #e5e5ec;
  padding: 3px;
}
.content-root.item-detail .table-container.basic .body-section .row .item.description .description-item-wrapper .input-wrapper {
  display: flex;
}
.content-root.item-detail .table-container.basic .body-section .row .item.description .description-item-wrapper .input-wrapper .description-input {
  width: 100%;
  border: 1px solid #fff;
  height: 80px;
  padding: 5px;
  resize: none;
}
.content-root.item-detail .table-container.basic .body-section .row .item.description .description-item-wrapper .input-wrapper .delete-description-btn {
  font-size: 30px;
  background-color: #fff;
  padding: 0 10px;
}
.content-root.item-detail .table-container.basic .body-section .row .item.description .description-item-wrapper .file-wrapper {
  display: flex;
  padding: 0 3px 3px;
}
.content-root.item-detail .table-container.basic .body-section .row .item.description .description-item-wrapper .file-wrapper .label {
  flex: 1;
  font-weight: 300;
  font-size: 14px;
}
.content-root.item-detail .table-container.basic .body-section .row .item.description .description-item-wrapper .file-wrapper .preview-btn {
  flex: 1;
  background-color: transparent;
  color: #661394;
  text-align: right;
}
.content-root.item-detail .table-container.basic .body-section .row .item.description .add-description-btn {
  width: 100%;
  background-color: #661394;
  padding: 10px;
  color: #fff;
}
.content-root.item-detail .table-container.stock .body-section {
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.content-root.item-detail .table-container.stock .stock-input {
  width: 140px;
  border: 1px solid #e5e5ec;
  height: 48px;
  padding: 5px;
  text-align: center;
  font-size: 16px;
}
.content-root.item-detail .table-container.stock .stock-input:focus {
  border-color: #1379d8;
}
.content-root.item-detail .table-container.cody .title-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.content-root.item-detail .table-container.cody .title-wrapper .table-title {
  font-size: 20px;
  font-weight: 600;
}
.content-root.item-detail .table-container.cody .title-wrapper .add-cody {
  font-size: 16px;
  width: 150px;
}
.content-root.item-detail .table-container.cody .product-image {
  width: 170px;
  height: 110px;
}
.content-root.item-detail .table-container.cody .delete-cody {
  width: 100px;
  font-size: 16px;
}/*# sourceMappingURL=item.detail.styles.css.map */