.content-root.coupon-detail {
  gap: 40px;
}
.content-root.coupon-detail .title-container {
  display: flex;
  justify-content: space-between;
}
.content-root.coupon-detail .title-container .button-wrapper {
  display: flex;
  gap: 10px;
}
.content-root.coupon-detail .body-section {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 38px 36px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.content-root.coupon-detail .body-section .limit-date-wrapper {
  display: flex;
  gap: 20px;
}
.content-root.coupon-detail .body-section .limit-date-wrapper .checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 300;
}
.content-root.coupon-detail .body-section .limit-date-wrapper .checkbox-wrapper input {
  width: 18px;
  height: 18px;
}
.content-root.coupon-detail .body-section .coupon-input-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}/*# sourceMappingURL=coupon.detail.styles.css.map */